<!--
 * @Author: CL
 * @Date: 2021-07-28 11:11:41
 * @LastEditTime: 2021-07-28 14:50:52
 * @Description: 旋转魔方组件
-->

<template>
  <div class="magic-contain">
    <div v-for="item in num" :key="item">{{ title }}</div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      num: 6
    }
  },

  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.magic-contain{
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  animation: move 10s linear alternate infinite;
  cursor: pointer;

  &:hover{
    > div{
      &:nth-of-type(1) {
        transform: rotateX(270deg) translateZ(100px);
      }
      &:nth-of-type(2) {
        transform: rotateY(271deg) translateZ(99px);
      }
      &:nth-of-type(3) {
        transform: rotateZ(0deg) translateZ(-98px);
      }
      &:nth-of-type(4) {
        transform: rotateY(90deg) translateZ(99px);
      }
      &:nth-of-type(5) {
        transform: rotateZ(0deg) translateZ(100px);
      }
      &:nth-of-type(6) {
        transform: rotateX(90deg) translateZ(99px);
      }
    }
  }

  >div{
    position: absolute;
    width: 100px;
    height: 100px;
    text-align: center;
    font-size: 18px;
    line-height: 100px;
    box-sizing: border-box;
    transition: all 0.8s;


    &:nth-of-type(1) {
      background-color: rgba(0, 28, 148, 0.1);
      transform: rotateX(270deg) translateZ(50px);
    }

    &:nth-of-type(2) {
      background-color: rgba(200, 158, 18, 0.1);
      transform: rotateY(271deg) translateZ(49px);
    }

    &:nth-of-type(3) {
      background-color: rgba(153, 154, 154, 0.1);
      transform: rotateZ(0deg) translateZ(-48px);
    }

    &:nth-of-type(4) {
      background-color: rgba(10, 233, 128, 0.1);
      transform: rotateY(90deg) translateZ(49px);
    }

    &:nth-of-type(5) {
      background-color: rgba(188, 111, 86, 0.1);
      transform: rotateZ(0deg) translateZ(50px);
    }

    &:nth-of-type(6) {
      background-color: rgba(165, 34, 56, 0.1);
      transform: rotateX(90deg) translateZ(49px);
    }
  }
}

@keyframes move {
  0% {
    transform: translate(-50%, -50%) rotate3d(1, 1, 1, 0);
  }
  100% {
    transform: translate(-50%, -50%) rotate3d(2, 3, 2, 360deg);
  }
}
</style>


