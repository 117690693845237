<!--
 * @Author: CL
 * @Date: 2021-07-27 17:25:18
 * @LastEditTime: 2021-07-30 17:55:31
 * @Description: 放松一下
-->

<template>
  <div class="relax-contain">
    <div class="text-wrap">
      <h4>学累了，放松一下吧！</h4>
      <p>不知道怎么选择就随机一下喽！</p>
      <button class="play" @click="goRanPlay">音乐OR游戏</button>
    </div>
    <div class="relax-wrap">
      <Magic @click.native="goMusic" title="音乐" />
    </div>
    <div class="relax-wrap">
      <Magic @click.native="goGames" title="游戏" />
    </div>

    <GoHome />
  </div>
</template>

<script>
import GoHome from '@/components/GoHome';
import Magic from '@/components/Magic';
import CarouseItem from '../Img/components/CarouseItem.vue';
import img from '@/assets/t1.jpg';

export default {
  data(){
    return {
      info: {
        bigImg: img,
        smallImg: img,
        title: '',
        subTitle: '',
        self: true
      }
    }
  },

  methods: {
    /**
     * 跳转到音乐页面
     */
    goMusic(){
      this.$router.push({
        name: 'music'
      })
    },

    /**
     * 跳转到游戏界面
     */
    goGames(){
      this.$router.push({
        name: 'games'
      })
    },

    /**
     * 随机跳转到音乐或者游戏界面
     */
    goRanPlay(){
      const ran = Math.random();
      if(ran > 0.5){
        this.$router.push({ name: 'music' });
      }else{
        this.$router.push({ name: 'games' })
      }
    }
  },

  components: {
    GoHome,
    Magic,
    CarouseItem
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.relax-contain{
  .text-wrap{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 999;

    h4{
      margin-bottom: 10px;
    }

    .play{
      width: 100px;
      text-align: center;
      line-height: 26px;
      font-size: 14px;
      background-image:linear-gradient(125deg,#2c3e50,#27ae60,#2980b9,#0080c0, #8e44ad, orange);
      background-size: 400%;   
      animation: move 8s infinite;
      color: #fff;
      padding: 6px 0;
      border-radius: 5px;
      margin-top: 15px;
      cursor: pointer;
      border: none;
    }
  }

  background: linear-gradient(125deg, rgba(255, 255, 255, 0.4), rgba(222, 222, 222, 0.4), rgba(190, 190, 190, 0.4), rgba(150, 150, 150, 0.4));
  background-size: 400%;   
  animation: move2 8s infinite;
  .self-fill();
  perspective: 3000px;
  transform-style: preserve-3d;

  .relax-wrap{
    .self-fill();
    width: 50%;

    &:nth-of-type(2){
      left: 50%;
    }
  }
}

@keyframes move {
	0% {
    background-position: 0% 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes move2 {
	0% {
    background-position: 0% 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>